import clsx from 'clsx';
import Image from 'next/image';
import { Button } from '../../../components/ui/button/Button';
import {
  Shimmer,
  toBase64,
} from '../../../components/utility/imageShimmer/ImageShimmer';
import type { ImageType } from '../../../types.d/storeTypes';
import styles from './ShareYourLooks.module.scss';

export type ShareYourLooksProps = {
  title: string;
  description: string;
  image: {
    desktop: ImageType;
    mobile: ImageType;
  };
  cta: {
    title: string;
    url: string;
    newTab: boolean;
  };
  isLoading: boolean;
};

/**
 * Share Your Looks component.
 * @param props - component props.
 */
export const ShareYourLooks = ({
  title,
  description,
  image,
  cta,
  isLoading,
}: ShareYourLooksProps) => {
  if (!image.mobile.url || !image.desktop.url) {
    return <></>;
  }

  if (isLoading) {
    return (
      <section className="container">
        <div className={clsx(styles.shareYourLooks, 'is-loading')}></div>
      </section>
    );
  }

  return (
    <section className="container">
      <div className={styles.shareYourLooks}>
        <div className={styles.shareYourLooks__imageWrapper}>
          <Image
            className={styles.shareYourLooks__imageMobile}
            src={image.mobile.url}
            alt={image.mobile.title}
            title={image.mobile.title}
            fill={true}
            sizes="100vw"
            placeholder="blur"
            blurDataURL={`data:image/svg+xml;base64,${toBase64(
              Shimmer(image.mobile.width, image.mobile.height)
            )}`}
          />

          <Image
            className={styles.shareYourLooks__imageDesktop}
            src={image.desktop.url}
            alt={image.desktop.title}
            title={image.desktop.title}
            fill={true}
            sizes="(min-width: 1440px) 1340px, 100vw"
            placeholder="blur"
            blurDataURL={`data:image/svg+xml;base64,${toBase64(
              Shimmer(image.desktop.width, image.desktop.height)
            )}`}
          />
        </div>

        <div className={styles.shareYourLooks__content}>
          {title && (
            <h2
              className={[
                styles.shareYourLooks__title,
                'text-heading-h2-mobile text-heading-h2-desktop',
              ].join(' ')}
            >
              {title}
            </h2>
          )}

          {description && (
            <p
              className={[
                styles.shareYourLooks__description,
                'text-heading-h6-mobile text-heading-h6-desktop',
              ].join(' ')}
            >
              {description}
            </p>
          )}

          {cta.url && cta.title && (
            <Button
              type="button"
              modifiers="text"
              href={cta.url}
              className={styles.shareYourLooks__link}
              target={cta.newTab ? '_blank' : undefined}
            >
              <span>{cta.title}</span>
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};
